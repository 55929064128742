import Head from "next/head";
import { title } from "@/config/site";
import { appWithTranslation } from "next-i18next";
import store, { persistor } from "@/store";
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import Script from "next/script"
import { useEffect, useMemo, useState } from "react";
import Layout from "@/components/layout";
import MobileLayout from "@/components/mobile/layout";
import Maintenance from "@/components/maintenance";
import { Image, LoadingOverlay } from "@mantine/core";
import * as Sentry from "@sentry/react";
import "../../public/assets/css/bootstrap.min.css"
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css'
import '@mantine/carousel/styles.css';
import "../../public/assets/css/style.css?v=3"
import "../../public/assets/css/responsive.css?v=3"
import { useTranslation } from 'next-i18next';
import { getCookie, removeCookies, setCookies } from "cookies-next";
import { _getURL } from "./api/index";
import { MantineProvider } from "@mantine/core";
import { GoogleTagManager } from '@next/third-parties/google'
import "../../public/assets/css/knockout.css"

if (process.env.NEXT_PUBLIC_ENV !== 'dev') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    tracesSampleRate: 1.0, //lower the value in production
  });
}

function MyApp({ Component, pageProps: { customWeb, removeMobileNav = false, header = true, footer = true, placementPage = '', meta = {}, ...pageProps } = {}, }) {
  const [state, setState] = useState({ device: null });
  const { t } = useTranslation('common');

  useEffect(() => {
    const _fetchData = async () => {
      window.history.prevUrl = [];
      setState({ device: (new URLSearchParams(window.location.search).get("device") ?? (window.innerWidth < 768 ? "mobile" : "desktop")) })
    }

    _fetchData()
  }, [])

  let userCookies = getCookie("user") && JSON.parse(getCookie("user"));
  userCookies = userCookies ?? undefined;

  const getLayout = Component.getLayout || null;
  const newProps = { ...pageProps, session: { user: userCookies }, device: state.device, t };

  return <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {() => (
        <>
          <Head>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <base href="./" />
            <title>{title}</title>
            <meta name='title' content={meta.title}></meta>
            <meta name='description' content={meta.description}></meta>
            <link rel="canonical" href={meta.canonical} />
            <meta property="og:title" content={meta.og_title} />
            <meta property='og:description' content={meta.og_description} />
            <meta property="og:image" content={meta.og_image} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:site_name" content={meta.og_site_name ?? "Ufootball"} />
            <meta property="og:image:url" content={meta.og_image} />
            <meta property="og:url" content={meta.og_url} />
            <meta property="og:type" content={meta.og_type ?? "website"} />
            <link rel="icon" href="/assets/images/favicon.ico" />
            <meta name='author' content='' />
            <meta property="fb:pages" content="101625819134401" />
            <meta property="fb:app_id" content="5586589554796536" />
            {
              meta.msvalidate_01 && <meta property="msvalidate_01" content={meta.msvalidate_01} />
            }
            {
              process.env.NEXT_PUBLIC_ENV == 'dev' ?
                <>
                  <script async src="https://www.googletagmanager.com/gtag/js?id=G-1RV1BP7NVJ"></script>
                  <script dangerouslySetInnerHTML={{
                    __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag() {dataLayer.push(arguments); }
                    gtag('js', new Date());

                    gtag('config', 'G-1RV1BP7NVJ');
                    `}} />
                </>
                :
                <>
                  <script async src="https://www.googletagmanager.com/gtag/js?id=G-WBMLCVG48J"></script>
                  <script
                    dangerouslySetInnerHTML={{
                      __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag() {dataLayer.push(arguments); }
                    gtag('js', new Date());

                    gtag('config', 'G-WBMLCVG48J');
                    `
                    }}
                  />
                </>
            }
            {
              process.env.NEXT_PUBLIC_ENV == 'dev' ?
                <script
                  dangerouslySetInnerHTML={{
                    __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-N4M9M7SX');
          `}}
                />
                :
                <>
                  {/* Google Tag Manager */}
                  <script
                    dangerouslySetInnerHTML={{
                      __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-KZM59649');
                `
                    }}
                  />
                  {/* End Google Tag Manager */}
                  {/* Google Tag Manager */}
                  <script
                    dangerouslySetInnerHTML={{
                      __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-PKQ7TLQ8');
                `
                    }}
                  />
                  {/* End Google Tag Manager */}
                </>
            }
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8754852794173836"
              crossorigin="anonymous"></script>
          </Head>

          {
            process.env.NEXT_PUBLIC_ENV == 'dev' ?
              <>
                {/* Google Tag Manager (noscript) */}
                <noscript
                  dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N4M9M7SX"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`
                  }}
                />
                {/* End Google Tag Manager (noscript) */}
              </>
              : <>
                {/* Google Tag Manager (noscript) */}
                <noscript
                  dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZM59649"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>`
                  }}
                />
                {/* End Google Tag Manager (noscript) */}
                {/* Google Tag Manager (noscript) */}
                <noscript
                  dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PKQ7TLQ8"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>`
                  }}
                />
                {/* End Google Tag Manager (noscript) */}
              </>
          }

          <MantineProvider className="this is a tseting ">
            {!customWeb
              &&
              <Image
                className="background-image"
                src="/assets/images/backrgound.jpeg"
              />
            }
            {
              process.env.NEXT_PUBLIC_MAINTENANCE_MODE == 1 ?
                <Maintenance />
                : getLayout ?
                  getLayout(<Component {...newProps} />, { device: state.device, t })
                  : state.device == "desktop" ?
                    <Layout header={header} footer={footer} placementPage={placementPage} customWeb={customWeb}>
                      <Component {...newProps} />
                    </Layout>
                    : state.device == "mobile" ?
                      <MobileLayout t={t} placementPage={placementPage} removeMobileNav={removeMobileNav} customWeb={customWeb}>
                        <Component {...newProps} />
                      </MobileLayout>
                      : <LoadingOverlay visible={true} />
            }
            <Script src="/assets/js/bootstrap.min.js" strategy="afterInteractive"></Script>
          </MantineProvider>

          <script
            dangerouslySetInnerHTML={{
              __html: `
            async function sendMetaEvent(title, params = { }, callback = () => { }) {
              await gtag('event', title,
                {
                  ...params,
                  'event_callback': callback
                }
              );
            `
            }} />
        </>
      )}
    </PersistGate>
  </Provider >

}

// MyApp.getInitialProps = async ({ router, ctx }) => {
//   try {
//     let { locale, asPath } = router;
//     if (asPath == "/app-download") {
//       return {
//         meta: {
//           title: "Ufootball - App Download",
//           description: "Ufootball app brings fans together in community. Get live scores, match stats, standings, fixtures, news and fan club from football leagues around the world.",
//           image: "https://ufootball.com.my/assets/images/app-icon.png?v=3",
//           url: "https://ufootball.com.my/app-download",
//           type: "article"
//         }
//       }
//     }
//     else if (asPath == "/privacy-policy") {
//       return {
//         meta: {
//           title: "Privacy Policy - Ufootball",
//           description: "",
//           url: "https://ufootball.com.my/privacy-policy",
//           image: "https://ufootball.com.my/assets/images/app-icon.png?v=3",
//         }
//       }
//     }
//     return {
//       meta: {
//         title,
//         description: ""
//       }
//     };

//   } catch (error) { console.log(error) }
// }

export default appWithTranslation(MyApp)