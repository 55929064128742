import Redux, { updatePath } from "@/action"
import { useLayoutEffect, useEffect, useState } from "react"
import { _getURL, _postURL } from "@/api/index";
import { HiMenu } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { menu } from "@/config/site"
import { map, isEmpty, forEach, findIndex } from "lodash";
// import Modal from 'react-modal';
import { Modal } from '@mantine/core';
import { Link } from "@/helpers/common_helper";
import { useRouter } from "next/router";
import { useDisclosure } from "@mantine/hooks";
import LanguageModal from "../../languagemodal";
import LandingPage from "./landingpage";

export default function Layout(props) {
  const { dispatch } = Redux();
  const { t, children, placementPage, removeMobileNav, device, customWeb } = props;
  const route = useRouter()
  const [openNav, setNav] = useState(false)
  const [opened, { open, close }] = useDisclosure(false);
  const [showMobileNav, setShowMobileNav] = useState(true);
  const [placement, setPlacement] = useState();
  const [refresh, setRefresh] = useState(0);
  const { locale, pathname, asPath, query } = route;
  const [language, setLanguage] = useState(locale);

  useEffect(() => {
    const handleLanguageSelectionChange = () => {
      const hasLanguageSelected = sessionStorage.getItem("languageSelected") == "true";
      setShowMobileNav(hasLanguageSelected);
    };

    // Listen for the custom event
    window.addEventListener('languageSelectionChanged', handleLanguageSelectionChange);

    _getCategory()

    return () => {
      window.removeEventListener('languageSelectionChanged', handleLanguageSelectionChange);
    };
  }, []);

  useEffect(() => {
    _getLive();
  }, [refresh])

  useLayoutEffect(() => {
    if (!localStorage.getItem("hasVisited")) {
      setIsFirstClientRender(true); // Show landing page only if the flag is not set
    }

    if (placementPage) {
      _getPlacement()
    }
    function updateSize() {
      if (window.innerWidth >= 768) {
        location.reload();
      }
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const _getPlacement = async () => {
    try {
      const deviceType = 1;
      const lang = locale;
      const placementUrl = `/api/common/getPlacement?type=${deviceType}&page=${JSON.stringify(["all", placementPage])}`;
      console.log('Making API Call:', placementUrl);  // Ensure URL is correct
      const response = await _getURL(placementUrl);
      console.log('Response:', response);

  
      if (!response || !response.data) {
        console.error("No data returned from placement API");
      } else {
        setPlacement(response.data);
      }
    } catch (error) {
      console.error("Error fetching placement:", error);
    }
  }

  const _onClickPlacement = async (id) => {
    try {
      await _postURL("/api/common/updatePlacementClick", {
        id
      });
    } catch (error) {
    }
  }

  const _getCategory = async () => {
    try {
      const result = await _postURL("/api/common/getCategory");
      dispatch(updatePath({ key: "category", path: result }))
    } catch (error) {
      console.log(error)
    }
  }

  const _getLive = async () => {
    try {
      let result = await _postURL("/api/thesport/getLive", {});
      let matches = {};
      let data = [];

      forEach(result.data, (value) => {
        let index = findIndex(data, ({ comp_id }) => { return comp_id == value.comp_id })
        matches[value.match_id] = value
        if (value.status_id < 8 && value.status_id > 1) {
          if (index < 0) {
            data.push({
              comp_id: value.comp_id,
              comp_logo: value.comp_logo,
              comp_name: value.comp_name,
              data: [value]
            });
          } else {
            data[index].data.push(value)
          }
        }
      })
      dispatch(updatePath({ key: "live", path: { data, matches } }))
    } catch (error) {
      console.log(error)
    }

    setTimeout(() => {
      setRefresh(refresh + 1)
    }, 20000)
  }

  const [isFirstClientRender, setIsFirstClientRender] = useState(true);
  const [showHeader, setShowHeader] = useState(false);

  const handleLandingPageComplete = () => {
    setIsFirstClientRender(false);
    setShowMobileNav(true);
  };

  const handleLanguageModalContinue = () => {
    setShowMobileNav(false);
    setShowHeader(true);
    sessionStorage.setItem('languageSelected', true);
    window.dispatchEvent(new Event('languageSelectionChanged'));
  };


  if (customWeb) {
    return <div className={`custom-web`}>
      {children}
    </div>
  }

  const mainPlacementImgStyle = {
    position: 'fixed',
    bottom: removeMobileNav ? '-20px' : '52px'
  };

  return <>
    {isFirstClientRender && <LandingPage setFirstTimeLandingPage={handleLandingPageComplete} />}
    {!showMobileNav && <LanguageModal opened={true} setOpened={setShowMobileNav} onContinue={handleLanguageModalContinue} />}
    {children}
    {!removeMobileNav && (
      <div className="mobile-nav">
        {[
          { path: "/", label: "Home", iconBase: "navi-community" },
          { path: "/match", label: "Match", iconBase: "navi-match" },
          { path: "/stats", label: "Stats", iconBase: "navi-stats" },
          { path: "/event/euro-2024-prediction", label: "Euro 2024", iconBase: "navi-euroevent" },
          { path: "/profile", label: "Profile", iconBase: "navi-profile" }
        ].map(({ path, label, iconBase }) => {
          const isActive = route.asPath === path;
          const iconPath = `/assets/images/icons/${iconBase}${isActive ? '-selected' : ''}.png`;
          return (
            <Link href={path} key={label} locale={route.locale}>
              <a className={isActive ? "active" : ""}>
                <img src={iconPath} alt={label} />
                <span>{t(label)}</span>
              </a>
            </Link>
          );
        })}
      </div>
    )}
    {/* <Modal
            classNames={{ inner: "mobile-nav-inner", body: "mobile-nav-modal" }}
            opened={opened} onClose={close} fullScreen
            withCloseButton={false}
        >
            <div className="mobile-nav-banner">
                <img
                    className="logo"
                    src="/assets/images/ufootball_logo_menu.png"
                    alt="site"
                />
            </div>
            <div className="mobile-nav-menu">
                {
                    map(menu.mobile, (value, key) => {
                        return <Link href={key} key={key}>
                            <a onClick={close} >
                                <img
                                    src={route.asPath == key ? value["acitve"] : value["inacitve"]}
                                    alt="site"
                                />
                                <br />
                                <span className={route.asPath == key && "color-primary"}>{t(value.label)}</span>
                            </a>
                        </Link>
                    })
                }
            </div>
        </Modal> */}
    {/* <Modal
            isOpen={openNav}
            ariaHideApp={false}
            className="mobile-nav-modal">
            <div className="mobile-nav-banner">
                <img
                    className="logo"
                    src="/assets/images/ufootball_logo_menu.png"
                    alt="site"
                />
            </div>
            <div className="mobile-nav-menu">
                {
                    map(menu.mobile, (value, key) => {

                        return <Link href={key} key={key}>
                            <a onClick={() => { setNav(!openNav) }} >
                                <img
                                    src={route.asPath == key ? value["acitve"] : value["inacitve"]}
                                    alt="site"
                                />
                                <br />
                                <span className={route.asPath == key && "color-primary"}>{t(value.label)}</span>
                            </a>
                        </Link>
                    })
                }
            </div>
        </Modal> */}

    {(placementPage && !isEmpty(placement)) && <a href={`${placement.url}`} target="_blank" onClick={() => { _onClickPlacement(placement.id) }}><img
      className="main-placement-img"
      src={`${process.env.NEXT_PUBLIC_FS_URL}${placement.image}`}
      style={mainPlacementImgStyle}
    /></a>}
  </>
}

