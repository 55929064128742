// LanguageModal.js
import { Modal, CloseButton } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { AiOutlineCheck } from 'react-icons/ai';
import { language } from '@/config/site';
import { forEach, reduce } from 'lodash';
import { useRouter } from 'next/router';

const LanguageModal = ({ opened, setOpened, onContinue }) => {
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;

  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const handleLanguageSelection = (language) => {
    router.push({ pathname, query }, asPath, { locale: language });
    setSelectedLanguage(language);
    setOpened(false);
  };

  const handleLanguageSelectionMobile = (language) => {
    router.push({ pathname, query }, asPath, { locale: language });
    setSelectedLanguage(language);
  };

  const handleContinue = () => {
    if (selectedLanguage) {
      onContinue();
    }
  };

  const Overlay = ({ show }) => show ? (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.65)', // Adjust color and opacity as needed
      zIndex: 1,
    }} />
  ) : null;

  if (isMobile&&opened) {
    return (
      <div className="full-screen-language-modal">
        <div className="language-modal-container">
          <div className="language-modal-header">
            <h1 className="language-modal-title-1">{t('welcome_to_ufootball')}</h1>
            <h4 className="language-modal-title-2">{t('choose_your_language')}</h4>
          </div>
          <h4 className="language-title">Language</h4>
          <div className='language-option-container'>
            {
              reduce(language, (result, value, key) => {
                result.push(
                  <div key={key} className='language-option' onClick={() => handleLanguageSelectionMobile(key)}>
                    <div className='language-name'>{value}</div>
                    {selectedLanguage == key && <div className='language-checkmark'><AiOutlineCheck /></div>}
                  </div>)
                return result
              }, [])
            }
          </div>
          <button
            className={`language-modal-choose-button ${!selectedLanguage && 'disabled-button'}`}
            onClick={handleContinue}
            disabled={!selectedLanguage}
          >
            {t('Continue')}
          </button>
          <p className='language-text'>{t('you_can_change_language')}</p>
        </div>
      </div>
    )
  }

  return (<>
    <Overlay show={opened} />
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title={t('choose_your_language')}
      centered
      overlaycolor="black"
      overlayopacity={0.6}
      overlayblur={3}
      classNames={{
        header: 'language-modal-header',
        title: 'language-modal-title',
        body: 'language-modal-body',
      }}
      withCloseButton={false}
    >
      <div className='language-option-container'>
        {
          forEach(language, (value, key) => {
            return <div key={key} className='language-option' disabled={!selectedLanguage} onClick={() => handleLanguageSelection(key)}>
              <div className={`language-circle ${selectedLanguage == key ? 'active-language' : ''}`}>
                <div className='language-inner-circle'></div>
              </div>
              <div className='language-name'>{value}</div>
            </div>
          })
        }
      </div>
      <p className='language-text'>{t('you_can_change_language')}</p>
    </Modal>
  </>
  );
};

export default LanguageModal;
