import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import LanguageModal from "../../languagemodal";

// const animationLandingPage_2 = {
//     initial: { 
//         scale: 1,
//         // opacity: 1,
//     },
//     animate: { 
//         scale: 20,
//         // opacity: 0,
//         transition: {
//             type: 'tween',
//             duration: 2,
//             bounce: 0,
//             delay: 1.5
//         }
//     },
// }

const animationLandingPage_3 = {
    initial: {
        opacity: 1,
        display: 'flex'
    },
    animate: {
        opacity: 0,
        transition: {
            type: 'tween',
            duration: 1,
            delay: 2,
        },
        transitionEnd: { display: "none" }
    },
}

const LandingPage = (props) => {
    const { setFirstTimeLandingPage = () => { } } = props;
    const [isLanguageModal, setLanguageModal] = useState(false);

    const changeLanguage = (language) => {
        console.log("change language", language)
    }
    
    return (
        <>
            <motion.div
                className='landing_page_position'
                initial="initial"
                animate="animate"
                variants={animationLandingPage_3}
                onAnimationComplete={() => { 
                    // setLanguageModal(true);
                    setFirstTimeLandingPage(false);
                    console.log("second time landing page")
                    localStorage.setItem("hasVisited", "true");
                }}
            >
                <div className='landing_page'>
                    <motion.div
                        className='landing_page_left'
                    >
                        <img
                            className='landing_page_image'
                            src={"assets/images/ufootball_logo_menu.png"}
                            onError={(e) => { e.target.onerror = null; e.target.src="/../assets/images/ufootball_logo_menu.png"; }}
                        />
                    </motion.div>
                    <div className='landing_page_center' />
                </div>
            </motion.div>

            {/* <LanguageModal
                opened={isLanguageModal}
                setOpened={setLanguageModal}
                changeLanguage={changeLanguage}
            /> */}
        </>
    )
}

export default LandingPage;