
import { Link } from "@/helpers/common_helper";
import { site_country } from "@/config/site";
import React, { useState } from 'react';
import { Image, TextInput } from "@mantine/core";
import { FaSearch } from 'react-icons/fa';
import { CgProfile } from 'react-icons/cg';
import { removeCookies } from 'cookies-next';

export default function Index({ session, t }) {
    return <>
        {/* < !--nav--> */}
        <nav className="nav py-xxl-3 py-md-2 sticky-top">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md-2">
                        <div className="logo w-100">
                            <Link href="/">
                                <a><img
                                    src="/assets/images/logo/logo.png"
                                    className="img-fluid"
                                    alt="site"
                                /></a>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                    {/* <div className="col-md-6 align-items-center text-center">
                        <TextInput
                            radius="xl"
                            placeholder={t("Search")}
                            icon={<FaSearch />}
                            styles={{
                                icon: {
                                    right: 0,
                                    left: "unset"
                                }
                            }}
                        />
                    </div> */}
                    <div className="col-md-3 d-flex justify-content-end align-items-center">
                        {session?.user != null ?
                            <>
                                <Image
                                    src={session.user.profile_image ? process.env.NEXT_PUBLIC_FS_URL + session.user.profile_image : "/assets/images/account/profile_default_image.png"}
                                    radius="xl"
                                    width={30}
                                    height={30} />
                                <div className="mx-2">
                                    {session.user.full_name}
                                </div>
                                |
                                <a onClick={() => {
                                    removeCookies("user");
                                    window.location = location.pathname;
                                }} className="ms-2">{t("Log Out")}</a>
                            </> :
                            <>
                                <Image src="/assets/images/account/profile_default_image.png"
                                    width={30}
                                    height={30} />

                                <Link href="/auth/login" >
                                    <a className="mx-2">
                                        {t("Login")}
                                    </a>
                                </Link>
                                {" | "}
                                <Link href="/sign-up">
                                    <a className="ms-2">
                                        {t("Sign Up")}
                                    </a>
                                </Link>
                            </>}
                        {/* <!-- forgot password ends here --> */}
                    </div>
                </div>
            </div>
        </nav>
        {/* <!-- nav ends here --> */}
    </>;
}
