import FingerprintJS from '@fingerprintjs/fingerprintjs'
import ip from "ip"

export function generate_sessiontoken() {
    var len = 20;
    var pool = '0123456789abcdefghijklmnopqrstuvwxyz';

    String.prototype.shuffle = function () {
        var a = this.split(""),
            n = a.length;

        for (var i = n - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var tmp = a[i];
            a[i] = a[j];
            a[j] = tmp;
        }
        return a.join("");
    }
    return pool.repeat(Math.ceil(len / pool.length)).shuffle().substr(0, len);
}

export function get_ip() {
    return ip.address();
}

export async function user_info(){
    // Get the client's fingerprint id
    const ip_address = get_ip();
    const sessiontoken = generate_sessiontoken();
    let FCMToken = await generateToken()

    let data = {
        ip_address: ip_address,
        sessiontoken: sessiontoken,
        FCMToken
    }

    return data;
}

export function errMsgHandling(err = {},default_msg = 'Error Occur'){
    let errJson = { message: default_msg };

    if(err.response && err.response.data && err.response.data.message){
        errJson.message = err.response.data.message;
    }
    else if(err.data && err.data.message){
        errJson.message = err.data.message;
    }
    else if(err.message){
        errJson.message = err.message;
    }
    else if(err && typeof err === 'string'){
        errJson.message = err;
    }

    return errJson;
}

export const generateToken = async () => {
    try {
        return await messaging().getToken();
    } catch (error) {
        return "dtdd_inMTUCh3Htss72yhQ:APA91bGpYUw0AdLk5yzXCzPEvnJPnT7FL-1Y7r_0PnsLToMLGJor43_TZyQmZcOyqcs32_tisA4ro6S_YxdNOr4hMkmc0eY89NNmQSvpzZ2Xv9CgzCeVubRW19yuaUpYlDfvmehnFDcj";
        // return "";
    }
}