import axios from "axios"
import crypto from 'crypto'
import { i18n } from 'next-i18next';
import { getCookie } from 'cookies-next';
import { errMsgHandling } from "@/helpers/api_helper";
import { toast } from "@/libraries/swal";

const timeout = 30000;
let state = {
    error_msg: null
}

export const generate_token = async (userCookies = null, lang = null, props = {}) => {
    try {
        const t = i18n ? i18n.t.bind(i18n) : null;
        let language = lang ?? t('lang');

        if (userCookies == null) {
            userCookies = getCookie("user") && JSON.parse(getCookie("user"));
        }
        userCookies = userCookies ? userCookies : undefined;
        let user_id = userCookies ? userCookies.user_id : "0";
        let sessiontoken = userCookies ? userCookies.token : "0";
        let user_role = 2;

        const encrypt_method = "AES-256-CBC";
        const secret_iv = process.env.NEXT_PUBLIC_SYSAPI_KEY;
        const key = process.env.NEXT_PUBLIC_SYSAPI_SECRET;

        const string = Buffer.from(JSON.stringify({
            'siv': secret_iv,
            'lang': language,
            'uid': user_id,
            'urole': user_role,
            't': Math.round((new Date()).getTime() / 1000),
            'stoken': sessiontoken,
            ...props
        })).toString("base64")

        const iv = crypto.createHash('sha256').update(secret_iv).digest('hex').substr(0, 16)

        const encrypter = crypto.createCipheriv(encrypt_method, key, iv);

        var content = encrypter.update(string, "utf8", "base64");

        content += encrypter.final("base64");
        return content;
    }
    catch (error) {
        state.error_msg = error.response.data.message ?? null;
        throw error;
    }
}

export const get = async (url = '', props = {}, token = null) => {
    try {
        if (token == null) {
            token = await generate_token();
        }
        let response = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}${url}`, {
            timeout: timeout,
            ...props,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'token': token,
                ...props.headers
            }
        });

        return response;
    }
    catch (error) {
        throw errMsgHandling(error);
    }
}

/** Default Axios for SYSAPI PSOT */
export const post = async (url = '', props = {}, token = null) => {
    try {
        if (token == null) {
            token = await generate_token();
        }
        let response = await axios({
            method: 'post',
            url: `${process.env.NEXT_PUBLIC_API_URL}${url}`,
            timeout: timeout,
            ...props,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': token,
                ...props.headers,
            },
        });
        return response;
    }
    catch (error) {
        throw errMsgHandling(error);
    }
}

/** Custom Axios for 3rd party API */
export const custom = async (url = '', props = {}) => {
    try {
        let response = await axios({
            method: 'get',
            url: url,
            timeout: timeout,
            ...props,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...props.headers,
            },
        })

        return response;
    }
    catch (error) {
        throw error;
    }
}

/** Custom Axios for 3rd party API POST */
export const _post = async (url, body, others = {}) => {
    try {
        const opt = {
            timeout: timeout,
            ...others,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...others.headers,
            },
        };

        let response = await axios.post(url, body, opt);

        return response;
    }
    catch (error) {
        throw errMsgHandling(error);
    }
}


/** Custom Axios for 3rd party API GET */
export const _get = async (url, others = {}) => {
    try {
        const opt = {
            timeout: timeout,
            ...others,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...others.headers,
            },
        };

        let response = await axios.get(url, opt);

        return response;
    }
    catch (error) {
        throw errMsgHandling(error);
    }
}

/** To call Next JS api route with GET method*/
export const _getURL = async (url, others = {}) => {
    try {
        const t = i18n.t.bind(i18n);
        const lang = t('lang');
        let token = await generate_token();
        url = url.indexOf('?') !== -1 ? `${url}&lang=${lang}&tk=${encodeURIComponent(token)}` : `${url}?lang=${lang}&tk=${encodeURIComponent(token)}`;
        let response = await _get(url, others);

        return response.data;
    }
    catch (error) {
        throw error;
    }
}

/** To call Next JS api route with POST method*/
export const _postURL = async (url, others = {}) => {
    try {
        let token = await generate_token();
        url = url.indexOf('?') !== -1 ? `${url}&tk=${encodeURIComponent(token)}` : `${url}?tk=${encodeURIComponent(token)}`;
        let response = await _post(url, others);

        return response.data;
    }
    catch (error) {
        throw error;
    }
}


export const _aesDecrypt = (data) => {
    const secret_iv = process.env.NEXT_PUBLIC_SYSAPI_KEY;
    const key = process.env.NEXT_PUBLIC_SYSAPI_SECRET;
    const encrypt_method = "AES-256-CBC";

    const iv = crypto.createHash('sha256').update(secret_iv).digest('hex').substr(0, 16)

    const decrypter = crypto.createDecipheriv(encrypt_method, key, iv);
    var content = decrypter.update(data, 'base64', 'utf8');
    content += decrypter.final("utf8");
    return content;
}
/** Blocker API */
export default (req, res) => {
    const { method } = req;

    switch (method) {
        default:
            res.status(405).end(`Method ${method} Not Allowed`);
            break;
    }
}