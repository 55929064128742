import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { i18n } from 'next-i18next'

const MySwal = withReactContent(Swal)

/****  Swal ****/
const swalConfirm = async ({ title = "Confirm", message = "Are you sure you want continue?", props = {} }) => {
    const t = i18n.t.bind(i18n);

    let result = await Swal.fire({
        title: t(title),
        text: t(message),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Yes'),
        cancelButtonText:t("Cancel"),
        ...props
    });

    return result;
}

const swalSuccess = async ({ title = "Success", message = "Success Response", props = {} }) => {
    const t = i18n.t.bind(i18n);

    return await Swal.fire({
        title: t(title),
        text: t(message),
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        // didOpen: (toast) => {
        //     toast.addEventListener('mouseenter', Swal.stopTimer)
        //     toast.addEventListener('mouseleave', Swal.resumeTimer)
        // },
        icon: 'success',
        ...props
    })
}

const swalError = async ({ title = "Fail", message = "Fail Response", props = {} }) => {
    const t = i18n.t.bind(i18n);

    return await Swal.fire({
        title: t(title),
        text: t(message),
        confirmButtonText: t("OK"),
        icon: 'error',
        ...props
    })
}

/****  Toast ****/
const toastSuccess = async (message, props = {}) => {
    const t = i18n.t.bind(i18n);

    const Toast = MySwal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        ...props
    })

    return Toast.fire({
        icon: 'success',
        title: t(message),
        ...props
    })
}

const toastError = async (req, props = {}) => {
    const t = i18n.t.bind(i18n);

    const Toast = MySwal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        customClass:{

        },  
        ...props
    });
    
    let message = 'Error';

    if(req.response && req.response.data && req.response.data.message){
        message = req.response.data.message;
    }
    else if(req.data && req.data.message){
        message = req.data.message;
    }
    else if(req.message){
        message = req.message;
    }
    else if(req && typeof req === 'string'){
        message = req;
    }

    return Toast.fire({
        icon: 'error',
        title: t(message),
        ...props
    })
}


export const swal = {
    confirm: swalConfirm,
    success: swalSuccess,
    error: swalError
}

export const toast = {
    success: toastSuccess,
    error: toastError
}

