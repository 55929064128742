
// Site name
export const site_name = "Ufootball";

// Default page title
export const title = "Ufootball";

// Google Analytics User ID (UA-XXXXXXXX-X)
export const ga_id = "";

// Menu Item 
// (or directly update page file: src/page/layout/navbar.js)
export const menu = {
    desktop: {
        '/': {
            label: "Home",
            img: "/assets/images/icons/menu-home-icon.svg"
        },
        '/event/euro-2024-prediction': {
            label: "Euro 2024",
            img: "/assets/images/icons/competition.svg"
        },
        '/match': {
            label: "Match",
            img: "/assets/images/icons/menu-match-icon.svg"
        },
        // '/stats': {
        //     label: "Stats",
        //     img: "/assets/images/icons/menu-stats-icon.svg"
        // },
        // '/games': {
        //     label: "Games",
        //     img: "/assets/images/icons/menu-game-icon.svg"
        // },
        // '/shop': {
        //     label: "Shop",
        //     img: "/assets/images/flag/id.png"
        // },
        // '/notifications': {
        //     label: "Notifications",
        //     img: "/assets/images/icons/menu-noti-icon.svg"
        // },        
    },
    mobile: {
        '/': {
            label: "Home",
            acitve: "/assets/images/menu/home_active.svg",
            inacitve: "/assets/images/menu/home_inactive.svg",
        },
        '/event/euro-2024-prediction': {
            label: "Euro 2024",
            acitve: "/assets/images/menu/competition_active.svg",
            inacitve: "/assets/images/menu/competition_inactive.svg"
        },
        '/match': {
            label: "Match",
            acitve: "/assets/images/menu/match_active.png",
            inacitve: "/assets/images/menu/match_inactive.png",
        },
        // '/stats': {
        //     label: "Stats",
        //     acitve: "/assets/images/menu/stats_active.png",
        //     inacitve: "/assets/images/menu/stats_inactive.png",
        // },
        // '/games': {
        //     label: "Games",
        //     acitve: "/assets/images/menu/game_active.png",
        //     inacitve: "/assets/images/menu/game_inactive.png",
        // },
        '/profile': {
            label: "Profile",
            acitve: "/assets/images/menu/profile_active.png",
            inacitve: "/assets/images/menu/profile_inactive.png",
        },
    },
    info: {
        aboutUs: {
            label: "About Us"
        },
        memberBenify: {
            label: "Member Benify"
        },
        userAgreement: {
            label: "User Agreement"
        },
        privaryPolicy: {
            label: "Privacy Policy"
        },
        cookiesPolicy: {
            label: "Cookies Policy"
        },
        advertisingCooperation: {
            label: "Advertising Cooperation"
        },
        contactUs: {
            label: "Contact Us"
        }
    }
};

export const fs_url = process.env.NEXT_PUBLIC_FS_URL;


export const options = {
    transaction_status: {
        1: 'Pending',
        2: 'Approved',
        3: 'Rejected',
        4: 'In Progress',
        5: 'Failed'
    },
    transfer_status: {
        1: 'Success',
        2: 'Failed',
        3: 'Revert',
        4: 'Pending',
    },
    bank_channel: {
        '1': 'Online Banking',
        '2': 'ATM',
        '3': 'Payment Gateway',
        '4': 'CDM'
    }
}

export const phone_currency = [
    {
        "code": "my",
        "label": "+60",
        "value": "+60",
        "image": "/assets/images/flag/my.png"
    },
    // {
    //     "code": "th",
    //     "label": "+66",
    //     "value": "+66",
    //     "image": "/assets/images/flag/th.png"
    // },
    // {
    //     "code": "id",
    //     "label": "+62",
    //     "value": "+62",
    //     "image": "/assets/images/flag/id.png"
    // }
]

export const language = {
    "en": "English",
    // Todo add Malay in the future
    // "bm": "Malay",
    "zh": "中文"
}

const Site = () => { };
export default Site;