import Footer from "./footer"
import Navbar from "./navbar"
import Header from "./header"
import { LoadingOverlay } from '@mantine/core';
import { useEffect, useState, useLayoutEffect } from "react"
import { _getURL, _postURL } from "@/api/index";
import { Image, Card, Text, Group, Button, rem } from '@mantine/core';
import Redux, { updatePath } from "@/action"
import { findIndex, forEach, isEmpty } from "lodash";
import { useRouter } from "next/router";

export default function Layout(props) {
    const { dispatch } = Redux();
    const { children, header, footer, showNavbar = true, placementPage, customWeb } = props;
    const { device } = children.props;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [placement, setPlacement] = useState()
    const [refresh, setRefresh] = useState(0);
    const router = useRouter();
    const { locale, pathname, asPath, query } = router;
    const [language, setLanguage] = useState(locale);

    useLayoutEffect(() => {
        if (placementPage) {
            _getPlacement()
        }

        function updateSize() {
            if (window.innerWidth < 768) {
                location.reload();
            }
        }
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        _getCategory()
    }, []);

    useEffect(() => {
        _getLive();
    }, [refresh])

    const _getPlacement = async () => {
        try {
            const deviceType = 2;
          const lang = locale;
          const placementUrl = `/api/common/getPlacement?type=${deviceType}&page=${JSON.stringify(["all", placementPage])}`;
          const response = await _getURL(placementUrl);
      
          if (!response || !response.data) {
            console.error("No data returned from placement API");
          } else {
            setPlacement(response.data);
          }
        } catch (error) {
          console.error("Error fetching placement:", error);
        }
      }

    const _onClickPlacement = async (id) => {
        try {
            await _postURL("/api/common/updatePlacementClick", {
                id
            });
        } catch (error) {
        }
    }

    const _getCategory = async () => {
        try {
            const result = await _postURL("/api/common/getCategory", { device: "desktop" });
            dispatch(updatePath({ key: "category", path: result }))
        } catch (error) {
            console.log(error)
        }
    }

    const _getLive = async () => {
        try {
            let result = await _postURL("/api/thesport/getLive", {});
            let matches = {};
            let data = [];

            forEach(result.data, (value) => {
                let index = findIndex(data, ({ comp_id }) => { return comp_id == value.comp_id })
                matches[value.match_id] = value
                if (value.status_id < 8 && value.status_id > 1) {
                    if (index < 0) {
                        data.push({
                            comp_id: value.comp_id,
                            comp_logo: value.comp_logo,
                            comp_name: value.comp_name,
                            data: [value]
                        });
                    } else {
                        data[index].data.push(value)
                    }
                }
            })
            dispatch(updatePath({ key: "live", path: { data, matches } }))

        } catch (error) {
            console.log(error)
        }

        setTimeout(() => {
            setRefresh(refresh + 1)
        }, 20000)
    }

    if (customWeb) {
        return <div className={`custom-web`}>
            {children}
        </div>
    }


    return <>
        {
            <div className={`container-fluid`}>
                <div className="row">
                    {/* {header && <Header {...children.props} langModal={setModalIsOpen} />} */}
                    {header && showNavbar && <Navbar {...children.props} />}
                    {/* <!-- main start here --> */}
                    <main className={`main col ${header ? "content" : ""}`}>
                        <div className="main-body">
                            {(placementPage && !isEmpty(placement)) && <a href={`${placement.url}`} target="_blank" onClick={() => { _onClickPlacement(placement.id) }}><img
                                className="main-placement-img"
                                src={`${process.env.NEXT_PUBLIC_FS_URL}${placement.image}`}
                            /></a>}
                            {children}
                        </div>
                    </main>
                    {/* <!-- main ends here --> */}
                    {/* {footer && <Footer {...children.props} />} */}
                </div>
            </div>
        }
    </>
}