import ip from "ip"
import LinkNext from "next/link";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/react";

export function generate_sessiontoken() {
    var len = 20;
    var pool = '0123456789abcdefghijklmnopqrstuvwxyz';

    String.prototype.shuffle = function () {
        var a = this.split(""),
            n = a.length;

        for (var i = n - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var tmp = a[i];
            a[i] = a[j];
            a[j] = tmp;
        }
        return a.join("");
    }
    return pool.repeat(Math.ceil(len / pool.length)).shuffle().substr(0, len);
}

export function get_ip() {
    return ip.address();
}

export function Link({ href = "/", children, ...props }) {
    const route = useRouter();
    return <LinkNext  href={"/"+route.locale + href}  locale={false} {...props} legacyBehavior>{children}</LinkNext>
}

export function log_message(msg) {
    let message = typeof msg === 'object' ? JSON.stringify(msg) : msg;
    Sentry.captureMessage(message);
}

export function getLang(){
    const route = useRouter();
    const locale = route.locale.toLowerCase()
    let arr = locale.split('-');

    return arr[1];
}